$form-dark-bg: rgba(#fff, 0.05);
$form-dark-bg-disabled: rgba(#fff, 0.1);

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.cursor-pointer {
  cursor: pointer;
}

.dark-theme {
  .nav-tabs .nav-link:not(.active) {
    color: var(--cui-primary);
  }

  .select__control {
    background: $form-dark-bg;
    border-color: $form-dark-bg;

    &:hover {
      border-color: $form-dark-bg;
    }
  }

  .select__single-value {
    color: #ffffff;
  }

  .form-control:disabled,
  .form-control:read-only,
  .select__control--is-disabled {
    background: $form-dark-bg-disabled;
    border-color: $form-dark-bg-disabled;
    cursor: not-allowed;
  }

  .select__input-container {
    color: white;
  }

  .dark-overlay.bg-white:not([class*='dark:']) {
    background-color: rgba(#000, 0.5) !important;
  }
}
